<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    quillEditor,
  },
  props: {
    placeholder: {
      type: String,
      default: "¿Cuales son los objetivos y requisitos de esta sección?",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: false,
      editorOption: {
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            [{ header: 1 }, { header: 2 }],
            // [{ 'font': [] }],
            ["bold", "italic", "underline"],
            // [{ 'color': [] }, { 'background': [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ 'align': [] }],
            ["link"],
            ["clean"],
          ],
        },
      },
    };
  },
  destroyed() {
    document.removeEventListener("keydown", this.keydownHandler);
  },
  methods: {
    onFocus() {
      if (!this.active) {
        document.addEventListener("keydown", this.keydownHandler);
      }

      this.active = true;
      this.$emit("focus");
    },
    onBlur() {
      document.removeEventListener("keydown", this.keydownHandler);
      this.active = false;
      this.$emit("blur");
    },
    keydownHandler(ev) {
      this.$emit("keydown", ev);

      if (ev.ctrlKey && ev.keyCode == 13) {
        this.$emit("ctrlEnter");
      }

      if (ev.keyCode == 27) {
        this.$emit("esc", this.onBlur);
      }

      if (ev.keyCode == 9) {
        this.$emit("tab", this);
      }
    },
  },
};
</script>

<template>
  <div>
    <div>
      <slot name="header"> </slot>
    </div>
    <quill-editor
      class="lego-quill"
      @input="$emit('input', $event)"
      :value="value"
      :class="{ active: active }"
      @focus="onFocus"
      @blur="onBlur"
      :options="editorOption"
    >
    </quill-editor>
    <slot
      name="footer"
      :focusQuill="onFocus"
      :blurQuill="onBlur"
      :value="value"
    >
      <Transition name="tc">
        <div class="controls">
          <slot
            name="controls"
            :focusQuill="onFocus"
            :blurQuill="onBlur"
            :value="value"
          >
          </slot>
        </div>
      </Transition>
    </slot>
  </div>
</template>

<style scoped>
.ql-editor {
  background-color: var(--lego-dbg);
}
.tc-enter-active,
.tc-leave-active {
  transition: all 0.5s ease-out;
  /* height: 28px; */
}

.tc-enter,
.tc-leave-to {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.controls {
  display: flex;
  gap: 0.5rem;
  margin-top: 0rem;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.lego-quill.active + .controls {
  margin-top: 2.3rem;
  opacity: 1;
  visibility: visible;
  height: 28px;
}

.controls > * {
  margin: 0;
}

.lego-quill {
  height: 150px;
  padding-bottom: 0;
  transition: 0.3s;
  padding-bottom: 1rem;
}
/* .active.lego-quill :deep() {
} */

.lego-quill :deep(.ql-toolbar.ql-snow) {
  height: 0px;
  padding: 0;
  visibility: hidden;
  transition: 0.3s;
  overflow: hidden;
  border-radius: 3px;
  opacity: 0;
  border-bottom: none;
}

.active.lego-quill :deep(.ql-toolbar) {
  height: 2.5rem;
  visibility: visible;
  padding: 8px;
  border-radius: 11px 11px 0 0;
  opacity: 1;
  border-color: #cacaca;
  .dark & {
    border-color: rgba(255, 255, 255, 0.25);
  }
}
.dark .lego-quill:deep(.ql-editor.ql-blank::before) {
  color: silver;
}

.lego-quill :deep(.ql-toolbar.ql-snow + .ql-container.ql-snow) {
  border-top: solid 1px #cacaca;
  border-color: #cacaca;
  .dark & {
    border-top: solid 1px rgba(255, 255, 255, 0.25);
    border-color: rgba(255, 255, 255, 0.25);
  }
  /* background-color: var(--lego-dbg) !important ;  */
}

.lego-quill :deep(.ql-container) {
  border: solid 1px #cacaca;
  border-top: solid 1px #cacaca;

  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
    border-top: solid 1px rgba(255, 255, 255, 0.25);
  }
  border-radius: 3px;
  transition: 0.5s;
}
.active.lego-quill :deep(.ql-container) {
  border-radius: 0 0 11px 11px;
  background-color: var(--lego-oscuro);
}
/* .ql-editor, quill-editor{
  background-color: var(--lego-dbg) !important ; 
} */
</style>
